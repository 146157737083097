import { notifyBugsnag } from "util/bugsnag";

export function sessionStorageGet(key) {
  try {
    if (!window.sessionStorage) return false;

    const encoded = window.sessionStorage.getItem(key);
    return encoded != null ? JSON.parse(encoded) : encoded;
  } catch (err) {
    notifyBugsnag(new Error("LocalStorageError"), {
      msg: "retrieving key: " + key,
      error: err,
    });
    return false;
  }
}

export function sessionStorageSet(key, value) {
  let str;

  try {
    if (!window.sessionStorage) return;

    str = JSON.stringify(value);
    window.sessionStorage.setItem(key, str);
  } catch (err) {
    notifyBugsnag(new Error("LocalStorageError"), {
      msg: "setting key: " + key,
      error: err,
    });
  }
}
