import flatten from "flat";
import qs from "qs";
import { metricsEventConfig } from "../config/metricsEventConfig";
import { notifyBugsnag } from "../util/bugsnag";
import { getAxios } from "../util/xhr";
import { sessionStorageGet } from "../shared/utils/sessionStorage";
import { getGlobalConfiguration } from "../util/global-configuration";
import {
  getAnonymousId,
  getClientWidth,
  getUserData,
  getUserGuid,
  refreshCurrentUser,
  isFirstTimeUser,
  isLoggedIn,
} from "../shared/utils/userService";

export const METRICS_PUBLISH_ROUTE = "/api/v3/polltime/publish";

export const TrMetricsService = {
  async defaultEventData() {
    const { locale } = getGlobalConfiguration();

    const query = document.location.search;

    /* eslint-disable camelcase */
    const {
      utm_campaign,
      utm_source,
      utm_medium,
      utm_term,
      utm_content,
      ref,
      gclid,
    } = qs.parse(query, { ignoreQueryPrefix: true });
    /* eslint-enable camelcase */

    const marketingReferrer = qs.stringify({
      utm_campaign,
      utm_source,
      utm_medium,
      utm_term,
      utm_content,
      ref,
      gclid,
    });

    return {
      guid: getUserGuid(),
      user_id: await getUserData("id"),
      client_width: getClientWidth(),
      metric_client: "web",
      locale,

      protocol: document.location.protocol,
      hostname: document.location.hostname,
      pathname: document.location.pathname,
      target_url: document.location.href,
      query,

      utm_campaign,
      utm_source,
      utm_medium,
      utm_term,
      utm_content,

      marketing_referrer: marketingReferrer,
      referrer: document.referrer,

      logged_in: isLoggedIn(),
      poster_status: await getUserData("poster_status"),
      first_time_user: await isFirstTimeUser(),

      epoch_ms: Date.now(),
      session_id: sessionStorageGet("tr_sess_id"),
      anonymous_id: getAnonymousId(),
    };
  },

  async sanitizeEventData(data, source) {
    return flatten(
      {
        ...(await this.defaultEventData()),
        ...{ eventSource: source },
        ...data,
      },
      {
        safe: true,
      }
    );
  },

  log(event, data) {
    if (
      !process.env.METRICS_DEBUG_ENABLED &&
      !process.env.GATSBY_METRICS_DEBUG_ENABLED
    ) {
      return;
    }

    // Metrics events should be logged to the console in development to make
    // debugging easier. In production, `console.log` should be removed by
    // Terser so nothing gets logged to the console.
    // eslint-disable-next-line no-console
    console.log(
      `%c[TrMetricsService]%c sending "%c${event}%c" to %c${
        data.eventSource
      }%c with data:\n%c${JSON.stringify(data, undefined, 2)}`,
      "color: #098D46; font-weight: bold;",
      "color: inherit",
      "font-weight: bold;",
      "",
      "font-weight: bold;",
      "",
      "color: #aaa;"
    );
  },

  shouldPublish(source) {
    // always publish bus metrics
    if (source === "bus") {
      return true;
    }

    if (!process.env.EXTERNAL_METRICS_ENABLED) {
      return false;
    }

    return true;
  },

  async publish(event, data, source) {
    if (!this.shouldPublish(source)) {
      return Promise.resolve(true);
    }

    // IKEA-1222 InvitationSent GTM (google tag manager) event does NOT fire for jobs booked in 1107
    // … and it still fires for all other GM jobs booked.
    if (data.skip?.includes(source)) {
      return Promise.resolve(true);
    }

    this.refreshUserData(event);
    const fullData = await this.sanitizeEventData(data, source);
    this.log(event, fullData);

    return this.sources[source](event, fullData);
  },

  async refreshUserData(event) {
    const userAuthenticationEvents = ["login_success"];
    if (userAuthenticationEvents.includes(event)) {
      refreshCurrentUser();
    }
  },

  fire(eventKey, data = {}) {
    const config = metricsEventConfig[eventKey] || {};

    // if your event should be sent to sources besides the bus,
    // or if you want the event name to be different from the event key
    // please add config for event to:
    // apps/web/src/config/metricsEventConfig.js

    if (!config.eventName) config.eventName = eventKey;
    if (!config.sources) config.sources = ["bus"];

    if (Array.isArray(config)) {
      const promises = config.map((eventConfig) => {
        return this.publishEvent(eventKey, data, eventConfig);
      });

      return Promise.all(promises);
    }
    return this.publishEvent(eventKey, data, config);
  },

  publishEvent(eventKey, data, eventConfig) {
    const { eventName, sources } = eventConfig;

    if (data) data.client_publish_key = eventKey;

    const promises = sources.map(async (source) => {
      return this.publish(eventName, data, source);
    });

    // start sending to all our integrations.
    const resultPromise = Promise.all(promises);

    return resultPromise;
  },

  // integrations
  sources: {
    bus(event, data) {
      const busData = {
        ...{ client_publish_type: event },
        ...data,
      };

      if (navigator.sendBeacon) {
        const jsonData = JSON.stringify(busData);
        return navigator.sendBeacon(METRICS_PUBLISH_ROUTE, jsonData);
      }
      const axios = getAxios();
      return axios.post(METRICS_PUBLISH_ROUTE, busData);
    },

    // Google Tag Manager
    gtm(event, data) {
      if (!window.gtmInterface) {
        if (event === "userCreated") {
          notifyBugsnag(new Error("GTM Error: Google Tag Manager not loaded"));
        }
        return Promise.resolve();
      }

      return new Promise((resolve) => {
        window.gtmInterface(event, data, resolve);
      });
    },

    ga(event, data) {
      if (!window.ga) {
        return Promise.resolve();
      }

      return new Promise((resolve) => {
        const newData = {
          ...{ hitType: event, hitCallback: resolve },
          ...data,
        };
        window.ga("send", newData);
      });
    },

    googleConversion(event) {
      window.google_conversion_id = 988819477;
      window.google_conversion_language = "en";
      window.google_conversion_format = "3";
      window.google_conversion_color = "ffffff";
      window.google_conversion_value = 0;
      window.google_remarketing_only = false;

      const conversions = {
        userCreated: "jWUNCLOdtgcQleDA1wM",
      };

      if (!window.google_conversion_function) {
        return Promise.resolve();
      }

      return new Promise((resolve) => {
        window.google_conversion_label = conversions[event] || event;
        window.google_conversion_function.call(window, resolve);
      });
    },
  },
};
