function showServicesList() {
  const dropDownRoot = document.querySelector(
    '[data-behavior="toggle-services-dropdown"]'
  );
  dropDownRoot
    .querySelector('[data-behavior="services-list"]')
    .classList.remove("is-hidden");
  dropDownRoot
    .querySelector('[data-behavior="services-link"]')
    .classList.add("is-focused");
}

function hideServicesList() {
  const dropDownRoot = document.querySelector(
    '[data-behavior="toggle-services-dropdown"]'
  );
  dropDownRoot
    .querySelector('[data-behavior="services-list"]')
    .classList.add("is-hidden");
  dropDownRoot
    .querySelector('[data-behavior="services-link"]')
    .classList.remove("is-focused");
}

export function registerServicesDropdown() {
  const servicesDropdownButton = document.querySelector(
    '[data-behavior="toggle-services-dropdown"]'
  );
  if (servicesDropdownButton) {
    servicesDropdownButton.addEventListener("mouseover", showServicesList);
    servicesDropdownButton.addEventListener("mouseout", hideServicesList);
  }
}
