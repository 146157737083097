export function toggleElement(targetElement) {
  const expanded = targetElement.getAttribute("data-expanded") === "yes";
  if (expanded) {
    targetElement.setAttribute("data-expanded", "no");
    collapseElement(targetElement);
  } else {
    targetElement.setAttribute("data-expanded", "yes");
    expandElement(targetElement);
  }
}

function expandElement(targetElement) {
  // If there is an element we're tracking with visibilityTracker.js inside a
  // collapsed (height=0) container, then the visibility tracker will fire as
  // soon as the collapsed container comes into view. The workaround is to set
  // the collapsed container's display to "none" until it is expanded for the
  // first time. This prevents the visibility tracker from firing before the
  // element is actually visible.
  targetElement.style.display = "block";

  targetElement.style.height = targetElement.scrollHeight + "px";

  // Set the style height to auto so if the contents resizes later
  // then the container will resize too.
  targetElement.addEventListener("transitionend", function cb(event) {
    targetElement.style.height = "auto";
    event.currentTarget.removeEventListener(event.type, cb);
  });
}

function collapseElement(targetElement) {
  targetElement.style.height = targetElement.scrollHeight + "px";
  // CSS transitions don't work if we start from height="auto", so we
  // have to set the height to 0px in the next iteration of the event loop.
  setTimeout(() => {
    targetElement.style.height = "0px";
  });
}
