import isNumber from "lodash/isNumber";

export function getCookie(name) {
  const data = document.cookie
    .split("; ")
    .map((pair) => {
      const [key, val] = pair.split("=");
      return { [decodeURIComponent(key)]: decodeURIComponent(val) };
    })
    .reduce((memo, pair) => {
      return Object.assign(memo, pair);
    }, {});

  return data[name];
}

function needsGDPRCompliance(locale) {
  // GDPR only applies for EU countries
  return [
    "en-GB",
    "de-DE",
    "ca-ES",
    "es-ES",
    "fr-FR",
    "it-IT",
    "pt-PT",
  ].includes(locale);
}

function cookieAllowedFromOneTrust(cookieName) {
  if (!needsGDPRCompliance(window.LOCALE)) {
    return true;
  }

  // We have some strictly necessary cookies, so we allow them regardless of consent
  const strictlyNecessaryCookies = [
    "CookieBannerHide",
    "dismissed-intended-use-banner",
    "ab",
    "uid",
    "tr-ikea-data",
  ];
  if (
    strictlyNecessaryCookies.includes(cookieName) ||
    cookieName.startsWith("alert-")
  ) {
    return true;
  }

  // OneTrust sets a global variable that stores all consented categories
  if (window.OnetrustActiveGroups) {
    let newCookieGroupCode;

    // Performance Cookies C0002
    if (cookieName === "trpromo" || cookieName === "event_metadata") {
      newCookieGroupCode = "C0002";
    }
    // Functional Cookies C0003
    else if (cookieName === "client_width") {
      newCookieGroupCode = "C0003";
    }
    // Targeting Cookies C0004
    else if (cookieName.startsWith("app-strt-rprtd-")) {
      newCookieGroupCode = "C0004";
    } else if (cookieName.startsWith("app-dn-rprtd-")) {
      newCookieGroupCode = "C0004";
    }

    if (newCookieGroupCode) {
      const allowedGroups = window.OnetrustActiveGroups.split(",");
      return allowedGroups.includes(newCookieGroupCode);
    }
  }

  // If OneTrust is not active/deployed, we deffer to block cookies
  return false;
}

export function setCookie(key, value, opts) {
  if (cookieAllowedFromOneTrust(key)) {
    const options = { path: "/", ...opts };

    /* jslint eqnull:true */
    if (value == null) options.expires = -1;

    if (isNumber(options.expires)) {
      const days = options.expires;
      options.expires = new Date();
      options.expires.setDate(options.expires.getDate() + days);
    }

    const newValue = String(value);

    document.cookie = [
      encodeURIComponent(key),
      "=",
      options.raw ? newValue : encodeURIComponent(newValue),
      options.expires ? "; expires=" + options.expires.toUTCString() : "", // use expires attribute, max-age is not supported by IE
      options.maxAge ? "; max-age=" + options.maxAge.toString() : "",
      options.path ? "; path=" + options.path : "",
      options.domain ? "; domain=" + options.domain : "",
      options.secure ? "; secure" : "",
    ].join("");
  }

  return document.cookie;
}
