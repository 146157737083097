import Bugsnag, { NotifiableError } from "@bugsnag/js";

export const notifyBugsnag = (
  errorMsg: NotifiableError = "ReactError",
  metadata?: Record<string, any>
) => {
  Bugsnag.notify(errorMsg, (event) => {
    if (metadata) {
      event.addMetadata("metadata", metadata);
    }
  });
};
