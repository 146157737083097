import { TrMetricsService } from "services/TrMetricsService";
import { DOWNLOAD_THE_APP_EVENT_NAME } from "../../config/metricsEventConfig";

function registerAppLinks() {
  const appLinkElements = document.querySelectorAll(
    '[data-behavior="app-link-clicked"]'
  );
  for (let i = 0; i < appLinkElements.length; i += 1) {
    const source = appLinkElements[i].getAttribute("data-source") || "unknown";
    appLinkElements[i].addEventListener("click", () => {
      TrMetricsService.fire(DOWNLOAD_THE_APP_EVENT_NAME, {
        source,
      });
    });
    appLinkElements[i].setAttribute("data-registered", "");
  }
}

let registrationComplete = false;

export function registerAppLinkListeners() {
  if (registrationComplete) {
    return;
  }
  registerAppLinks();
  registrationComplete = true;
}
