import { useRef } from "react";

const configuration = {};
const configurationKeys = ["locale"];

export function useUpdateGlobalConfiguration({ locale }) {
  const isGlobalConfigurationUpdatedRef = useRef(false);
  if (isGlobalConfigurationUpdatedRef.current) {
    return;
  }
  updateGlobalConfiguration({ locale });
  isGlobalConfigurationUpdatedRef.current = true;
}

function updateGlobalConfiguration(newConfiguration) {
  for (let i = 0; i < configurationKeys.length; i += 1) {
    const key = configurationKeys[i];
    if (newConfiguration[key] != null) {
      configuration[key] = newConfiguration[key];
    }
  }
}

export function getGlobalConfiguration() {
  const configurationDefaults = getConfigurationDefaults();
  return { ...configurationDefaults, ...configuration };
}

function getConfigurationDefaults() {
  const windowGlobal = typeof window === "undefined" ? {} : window;
  return { locale: windowGlobal.LOCALE };
}
