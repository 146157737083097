import { registerHamburgerMenu } from "../headerHamburgerMenu";
import { registerServicesDropdown } from "../headerServicesDropdown";
import { registerLanguageToggleDropdown } from "../headerLanguageToggleDropdown";
import { registerVisibilityTrackers } from "../utils/visibilityTracker";

export function prepareHeader() {
  registerHamburgerMenu();
  registerServicesDropdown();
  registerLanguageToggleDropdown();
  registerVisibilityTrackers();
}
