import get from "lodash/get";
import { setCookie, getCookie } from "../../util/cookie";
import { getAxios } from "../../util/xhr";
import { SESSION_COOKIE_NAME } from "../../util/constants";
import { isEnvironmentServerSide } from "./is-environment-server-side";

let currentUser;
let requestPromise;

export async function refreshCurrentUser() {
  const url = `/current_user`;

  // Only allow one request to be in-flight at a time.
  if (requestPromise == null) {
    const axios = getAxios();
    requestPromise = axios.get(url);
  }
  currentUser = (await requestPromise).data;
  requestPromise = null;
}

async function getCurrentUser() {
  if (currentUser == null) {
    await refreshCurrentUser();
  }

  return currentUser;
}

export function getAnonymousId() {
  if (typeof window.analytics.user === 'function') {
    return window.analytics.user().anonymousId();
  }
  const id = localStorage.getItem("ajs_anonymous_id");
  return id ? JSON.parse(id) : null;
}

export function getUserGuid() {
  return getCookie("uid");
}

export function getClientWidth() {
  const clientWidth = window.innerWidth;
  setCookie("client_width", clientWidth);
  return clientWidth;
}

export async function getUserData(path, defaultValue) {
  await getCurrentUser();

  return get(currentUser, path, defaultValue);
}

export function isLoggedIn() {
  if (isEnvironmentServerSide()) {
    return false;
  }
  const value = getCookie(SESSION_COOKIE_NAME);
  return !!value;
}

export async function isFirstTimeUser() {
  return (await getUserData("poster_status")) !== "returning";
}
