function showServicesList() {
  const dropDownRoot = document.querySelector(
    '[data-behavior="toggle-language-dropdown"]'
  );
  dropDownRoot
    .querySelector('[data-behavior="languages-list"]')
    .classList.remove("is-hidden");
}

function hideServicesList() {
  const dropDownRoot = document.querySelector(
    '[data-behavior="toggle-language-dropdown"]'
  );
  dropDownRoot
    .querySelector('[data-behavior="languages-list"]')
    .classList.add("is-hidden");
}

export function registerLanguageToggleDropdown() {
  const languageToggleDropdownButton = document.querySelector(
    '[data-behavior="toggle-language-dropdown"]'
  );
  if (languageToggleDropdownButton) {
    languageToggleDropdownButton.addEventListener("mouseover", showServicesList);
    languageToggleDropdownButton.addEventListener("mouseout", hideServicesList);
  }
}
