import { toggleElement } from "./trExpander";

function elementByBehavior(behaviorName) {
  return document.querySelector(`[data-behavior="${behaviorName}"]`);
}

function toggleHamburgerMenu() {
  const hamburgerMenu = document.querySelector(
    '[data-behavior="hamburger-menu-content"]'
  );
  toggleElement(hamburgerMenu);
}

function toggleHamburgerServicesIcon() {
  const hamburgerServicesButton = document.querySelector(
    '[data-behavior="toggle-hamburger-services-menu"]'
  );
  hamburgerServicesButton
    .querySelector(".ss-lnr-circle-minus")
    .classList.toggle("u-hidden");
  hamburgerServicesButton
    .querySelector(".ss-lnr-plus-circle")
    .classList.toggle("u-hidden");
}

function toggleHamburgerServicesMenu() {
  const servicesSection = document.querySelector(
    '[data-behavior="hamburger-services-section"]'
  );
  toggleElement(servicesSection);
  toggleHamburgerServicesIcon();
}

function toggleSidebarMenu() {
  document.body.classList.toggle("sidebar-navigation-open");
  if (document.body.classList.contains("sidebar-navigation-open")) {
    resetScrollableContentToTop();
    focusMainMenu();
    displayMainMenu();
  }
}

function resetScrollableContentToTop() {
  const scrollableElements = elementsByBehavior("sidebar-scrollable-content");
  for (let i = 0; i < scrollableElements.length; i++) {
    scrollableElements[i].scrollTop = 0;
  }
}

function focusMainMenu() {
  const closeBtn = document.querySelector(".sidebar-close-icon");
  closeBtn.focus();
}

function toggleServicesMenu() {
  const mainMenuElement = elementByBehavior("sidebar-main-menu");
  const servicesMenuElement = elementByBehavior("sidebar-services-menu");
  mainMenuElement.classList.toggle("u-hidden");
  servicesMenuElement.classList.toggle("u-hidden");
  resetScrollableContentToTop();
}

function toggleLanguageMenu() {
  const mainMenuElement = elementByBehavior("sidebar-main-menu");
  const languagesMenuElement = elementByBehavior("sidebar-languages-menu");
  if(mainMenuElement) mainMenuElement.classList.toggle("u-hidden");
  if(languagesMenuElement) languagesMenuElement.classList.toggle("u-hidden");
  resetScrollableContentToTop();
}

function displayMainMenu() {
  const servicesMenuElement = elementByBehavior("sidebar-services-menu");
  const languagesMenuElement = elementByBehavior("sidebar-languages-menu");
  if(servicesMenuElement) servicesMenuElement.classList.add("u-hidden");
  if(languagesMenuElement) languagesMenuElement.classList.add("u-hidden");
}

function focusMenu() {
  const lastItem = elementByBehavior("last-item-focus");
  lastItem.addEventListener('blur', focusMainMenu);
}

function elementsByBehavior(behaviorName) {
  return document.querySelectorAll(`[data-behavior="${behaviorName}"]`);
}

function addBehavior({ behaviorName, fn }) {
  const elements = elementsByBehavior(behaviorName);
  for (let i = 0; i < elements.length; i++) {
    elements[i].addEventListener("click", fn);
  }
}

export function registerHamburgerMenu() {
  addBehavior({
    behaviorName: "toggle-hamburger-menu",
    fn: toggleHamburgerMenu,
  });
  addBehavior({
    behaviorName: "toggle-hamburger-services-menu",
    fn: toggleHamburgerServicesMenu,
  });
  addBehavior({ behaviorName: "toggle-sidebar-menu", fn: toggleSidebarMenu });
  addBehavior({ behaviorName: "toggle-services-menu", fn: toggleServicesMenu });
  addBehavior({ behaviorName: "toggle-languages-menu", fn: toggleLanguageMenu });
  focusMenu();
}
