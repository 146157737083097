// import camelCase from "camelcase";
import { snakeCase as snake_case } from "snake-case";

const preserveCamelCase = function preserveCamelCase(string) {
  let isLastCharLower = false;
  let isLastCharUpper = false;
  let isLastLastCharUpper = false;

  for (let i = 0; i < string.length; i++) {
    const character = string[i];

    if (
      isLastCharLower &&
      /[a-zA-Z]/.test(character) &&
      character.toUpperCase() === character
    ) {
      string = string.slice(0, i) + "-" + string.slice(i);
      isLastCharLower = false;
      isLastLastCharUpper = isLastCharUpper;
      isLastCharUpper = true;
      i++;
    } else if (
      isLastCharUpper &&
      isLastLastCharUpper &&
      /[a-zA-Z]/.test(character) &&
      character.toLowerCase() === character
    ) {
      string = string.slice(0, i - 1) + "-" + string.slice(i - 1);
      isLastLastCharUpper = isLastCharUpper;
      isLastCharUpper = false;
      isLastCharLower = true;
    } else {
      isLastCharLower =
        character.toLowerCase() === character &&
        character.toUpperCase() !== character;
      isLastLastCharUpper = isLastCharUpper;
      isLastCharUpper =
        character.toUpperCase() === character &&
        character.toLowerCase() !== character;
    }
  }

  return string;
};

const camelCase = function camelCase(input, options) {
  if (!(typeof input === "string" || Array.isArray(input))) {
    throw new TypeError("Expected the input to be `string | string[]`");
  }

  options = {
    pascalCase: false,
    ...options
  };

  const postProcess = function postProcess(x) {
    return options.pascalCase ? x.charAt(0).toUpperCase() + x.slice(1) : x;
  };

  if (Array.isArray(input)) {
    input = input
      .map(function(x) {
        return x.trim();
      })
      .filter(function(x) {
        return x.length;
      })
      .join("-");
  } else {
    input = input.trim();
  }

  if (input.length === 0) {
    return "";
  }

  if (input.length === 1) {
    return options.pascalCase ? input.toUpperCase() : input.toLowerCase();
  }

  const hasUpperCase = input !== input.toLowerCase();

  if (hasUpperCase) {
    input = preserveCamelCase(input);
  }

  input = input
    .replace(/^[_.\- ]+/, "")
    .toLowerCase()
    .replace(/[_.\- ]+(\w|$)/g, function(_, p1) {
      return p1.toUpperCase();
    })
    .replace(/\d+(\w|$)/g, function(m) {
      return m.toUpperCase();
    });
  return postProcess(input);
};

const changeCasingOfKeys = (casingMethod, obj) => {
  if (!obj) {
    return obj;
  }

  if (obj instanceof Date) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(element => changeCasingOfKeys(casingMethod, element));
  }

  if (typeof obj === "object") {
    return Object.entries(obj).reduce((casedObj, [k, v]) => {
      casedObj[casingMethod(k)] = changeCasingOfKeys(casingMethod, v);
      return casedObj;
    }, {});
  }

  return obj;
};

export const camelCaseKeys = res => changeCasingOfKeys(camelCase, res);
export const snake_caseKeys = data => changeCasingOfKeys(snake_case, data);
