// onDocumentReady accepts one function argument. It runs that function when
// the DOM has finished loading and returns a promise that resolves to the
// return value of the function passed in.
export function onDocumentReady(fn) {
  return new Promise((resolve) => {
    if (
      document.readyState === "complete" ||
      document.readyState === "interactive"
    ) {
      setTimeout(() => resolve(fn()));
    } else {
      document.addEventListener("DOMContentLoaded", () => resolve(fn()));
    }
  });
}
