import { onDocumentReady } from "./utils/onDocumentReady";

function lazyLoadImagesCallback(entries, observer) {
  for (let i = 0; i < entries.length; i++) {
    const entry = entries[i];
    if (entry.isIntersecting) {
      const element = entry.target;
      loadImage(element);
      observer.unobserve(element);
    }
  }
}

function observeLazyLoadImages() {
  // Load the image if it is within `rootMargin * viewportHeight` from the viewport
  const rootMargin = "50%";
  const options = { rootMargin };

  const observer = new IntersectionObserver(lazyLoadImagesCallback, options);

  const elements = document.querySelectorAll("img, div[data-lazy-background]");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    observer.observe(element);
  }
}

function loadImage(element) {
  if (element.tagName === 'IMG') {
    copyImgAttributesFromData(element);
  } else {
    copyDivStyleFromData(element);
  }
}

function copyDivStyleFromData(div) {
  const backgroundUrl = div.getAttribute("data-lazy-background");

  if (backgroundUrl) {
    div.style.backgroundImage = `url("${backgroundUrl}")`;
  }
}

function copyImgAttributesFromData(img) {
  const src = img.getAttribute("data-src");
  const srcset = img.getAttribute("data-srcset");

  if (src) {
    img.setAttribute("src", src);
  }
  if (srcset) {
    img.setAttribute("srcset", srcset);
  }
}

function loadAllImages() {
  const elements = document.querySelectorAll("img, div[data-lazy-background]");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    loadImage(element);
  }
}

let registrationComplete = false;

function loadImages() {
  if (registrationComplete) {
    return;
  }

  if ("IntersectionObserver" in window) {
    observeLazyLoadImages();
  } else {
    loadAllImages();
  }

  registrationComplete = true;
}

export function lazyLoadImages() {
  onDocumentReady(() => {
    loadImages();
  });
}
