/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import qs from "qs";
import { createAlert } from "alerts/redux/modules/alerts";
// eslint-disable-next-line camelcase
import { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { V3Thunk } from "util/reduxTypes";
import { snake_caseKeys } from "./casing";
import { buildAxiosClient } from "./xhr/configure-axios";
import { StripeError } from "@stripe/stripe-js";

const xhr = buildAxiosClient();
/**
 * The `xhr` export is deprecated because it is initialized on import. The
 * replacement is to call getAxios() which initializes Axios when run instead.
 */
export default xhr;

let axiosInstance: AxiosInstance;
export function getAxios() {
  if (axiosInstance == null) {
    axiosInstance = buildAxiosClient();
  }
  return axiosInstance;
}

export const snakeCaseKeysAndQueryStringify = (
  data: Record<string, unknown>,
  options: qs.IStringifyOptions = { skipNulls: true, arrayFormat: "brackets" }
) => qs.stringify(snake_caseKeys(data), options);

export function createApiErrorAlert(
  error: AxiosError | { response: { data: StripeError | unknown } },
  type = "error"
): V3Thunk {
  const errors = errorsFromResponse(error.response);
  return createAlert({
    messages: errors,
    skipI18n: true,
    autoRemove: false,
    type,
  });
}

export function errorsFromResponse(
  response?: AxiosResponse | { data: StripeError | unknown }
): string[] {
  const data = response?.data;
  if (!data) {
    return [];
  }

  type Error = { message?: string } | string;
  let errors: Error | Error[] = data.error || data.message || data.errors || {};
  if (!Array.isArray(errors)) {
    errors = [errors];
  }

  return errors
    .map((e) => (typeof e === "string" ? e : e.message))
    .filter((v): v is string => !!v);
}

// If the error is from Stripe, this function will reformat it to work with our existing methods
// Otherwise returns the original error
export function errorFromStripe(
  error: StripeError | AxiosError | unknown
): AxiosError | { response: { data: StripeError | unknown } } {
  let e;
  if (instanceOfStripeError(error)) {
    e = {
      response: {
        data: error,
      },
    };
    return e;
  }

  return error as AxiosError;
}

export function instanceOfStripeError(
  object: any
): object is StripeError {
  return (
    object !== null &&
    typeof object === "object" &&
    typeof (object as StripeError).type !== "undefined"
  );
}
