import axios, { AxiosError, AxiosInstance } from "axios";
import qs from "qs";
import countryIsoCodeFromLocale from "../countryIsoCodeFromLocale";
import { notifyBugsnag } from "../bugsnag";
import { camelCaseKeys } from "../casing";
import { getGlobalConfiguration } from "../global-configuration";
import { isEnvironmentServerSide } from "../../shared/utils/is-environment-server-side";

declare module "axios" {
  interface AxiosResponse<T = any> {
    camelCasedData: T;
  }
}

const NON_ERROR_HTTP_STATUS_CODES = [401, 403, 422];

export function buildAxiosClient(): AxiosInstance {
  if (isEnvironmentServerSide()) {
    return brokenAxiosClient(
      "Developer error: You've attempted to make an API call in a server-side " +
        "environment. No API calls should be made server-side. This can " +
        "typically be fixed by wrapping your API call in a useEffect hook. The " +
        "useEffect hook will delay your API call until after the first render " +
        "has completed and guarantees that your code is running client-side."
    );
  }

  const headers = buildHeaders();
  if (headers == null) {
    return brokenAxiosClient(
      "Developer error: Unable to find required headers while building an " +
        "Axios instance. This probably happened because you used " +
        "`import xhr from 'util/xhr'` in a file that was imported by a module " +
        "used in Gatsby. This error can be corrected by changing your import " +
        "to `import { getAxios } from 'util/xhr'` and changing any usages of " +
        "`xhr.xyz()` to `const axios = getAxios(); axios.xyz()`. Building the " +
        "Axios instance only when it is needed instead of when it is imported " +
        "gives enough time to call `updateGlobalConfiguration()` and set the " +
        "required variables before building the Axios instance."
    );
  }

  const axiosClient = axios.create({
    headers,
    paramsSerializer: (params) =>
      qs.stringify(params, { skipNulls: true, arrayFormat: "brackets" }),
  });

  setAxiosClientInterceptors(axiosClient);

  return axiosClient;
}

function setAxiosClientInterceptors(axiosClient: AxiosInstance) {
  axiosClient.interceptors.response.use(
    (res) => {
      res.camelCasedData = camelCaseKeys(res.data);
      return res;
    },
    (error: AxiosError) => {
      const newError = error;
      if (
        error.response &&
        !NON_ERROR_HTTP_STATUS_CODES.includes(error.response.status)
      ) {
        // data is the posted data, but it is stringified.  bugsnag has a
        // redacted list that checks for specific keys, but it doesn't work
        // if it's stringified, so let's get this back into javascript.
        const jsonData = error.config.data;
        if (jsonData) {
          try {
            newError.config.data = JSON.parse(jsonData);
          } catch (e) {
            // swallow
          }
        }
        notifyBugsnag("XhrError", newError);
      }
      return Promise.reject(newError);
    }
  );

  return axiosClient;
}

function buildHeaders() {
  const token = getCSRFToken();
  const { locale } = getGlobalConfiguration();
  const countryIsoCode = countryIsoCodeFromLocale(locale);
  if (locale == null || token == null || countryIsoCode == null) {
    return null;
  }
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "X-CSRF-TOKEN": token,
    "Accept-Language": locale,
    "X-COUNTRY-ISO-CODE": countryIsoCode,
  };
  return headers;
}

/**
 * This function returns an object that throws an error with the given
 * explanation if any of its properties are called as functions.
 */
function brokenAxiosClient(explanation: string): AxiosInstance {
  return new Proxy({} as AxiosInstance, {
    apply: () => {
      throw Error(explanation);
    },
    get: (target, prop, receiver) => {
      if (
        typeof prop === "string" &&
        ["get", "put", "delete", "post"].includes(prop)
      ) {
        // eslint-disable-next-line no-console
        console.error(explanation);
        throw Error(explanation);
      }

      return Reflect.get(target, prop, receiver);
    },
  });
}

function getCSRFToken() {
  // Use fake CSRF token in environments (test) where the token isn't present
  // or validated.
  const csrfElement = document.querySelectorAll('meta[name="csrf-token"]');
  return csrfElement.length > 0
    ? csrfElement[0].getAttribute("content")
    : "NA-FAKE-CSRF-TOKEN";
}
