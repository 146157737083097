import { TrMetricsService } from "services/TrMetricsService";

require("intersection-observer");

// HOW TO USE
// This module will fire a tracking event when specified elements come into
// view. To track an element, add a "data-track-visibility" attribute to it.
// When the element comes into view, the event in the element's "data-event"
// attribute will be fired. The JSON string in the element's "data-payload"
// attribute will be parsed and sent as the event's "data".

function fireTrackingEvent(element) {
  const payload = JSON.parse(element.getAttribute("data-payload"));
  const event = element.getAttribute("data-event");
  TrMetricsService.fire(event, payload);
}

function observerCallback(entries, observer) {
  for (let i = 0; i < entries.length; i++) {
    const entry = entries[i];
    if (entry.isIntersecting) {
      const trackedElement = entry.target;
      fireTrackingEvent(trackedElement);
      observer.unobserve(trackedElement);
    }
  }
}

let registrationComplete = false;

export function registerVisibilityTrackers() {
  if (registrationComplete) {
    return;
  }

  const observer = new IntersectionObserver(observerCallback);
  const trackedElements = document.querySelectorAll("[data-track-visibility]");

  for (let i = 0; i < trackedElements.length; i++) {
    observer.observe(trackedElements[i]);
  }

  registrationComplete = true;
}
